import $ from "jquery";
import startAutoSendToggles from "../../utilities/auto_send_toggles";
import JustValidate from 'just-validate';
import globalConfigFormValidator from "../../utilities/formValidatorData";
import {allFields, chileanFields, colombianFields, defaultFields, mexicanFields} from "./financierBillingFormFields";

const hideCountryFields = (countries) => {
  countries.forEach((country) => {
    const countryFields = $(`#${country}_fields`)
    countryFields.hide()
  })
}

const showCountryFields = (countries = []) => {
  countries.forEach((country) => {
    const countryFields = $(`#${country}_fields`)

    countryFields.show()
  })
}

const countryConfig = {
  '142': { // Mexico
    show: ["mexico"],
    hide: ["chilean", "colombia"]
  },
  '50': { // ID para Colombia
    show: ["colombia"],
    hide: ["chilean", "mexico"]
  },
  '1': { // ID para Chile
    show: ["chilean"],
    hide: ["colombia", "mexico"]
  }
}

const toggleCountryFields = () => {

  const billingAddressCountry = $("#billing_address_country_id")
  const selectedCountryId = $('#financier_country_select').val();

  addOrRemoveFieldsFormValidator(selectedCountryId)

  setCountryToBilling(billingAddressCountry)

  const otherFields = Object.keys(countryFields).reduce((acc, key) => {
    if (key !== selectedCountryId.toString()) {
      acc[key] = countryFields[key];
    }
    return acc;
  }, {});

  Object.values(otherFields).forEach((field) => {
    Object.keys(field).forEach((fieldName) => {
      $(fieldName).val("");
    })
  })


  const config = countryConfig[selectedCountryId]

  if (config) {
    showCountryFields(config.show);
    hideCountryFields(config.hide);
  } else {
    hideCountryFields(["colombia", "chilean", "mexico"]);
  }

};


const startToggleSections = () => {
  // Ocultar secciones inicialmente
  const insuranceSection = $('.financier-insurance-section');
  const creditSection = $('.financier-credit-section');

  const disableFields = (section) => {
    section.find('input, select, textarea').prop('disabled', true);
  };

  const enableFields = (section) => {
    section.find('input, select, textarea').prop('disabled', false);
  };

  // Inicialmente deshabilitar ambas secciones
  disableFields(insuranceSection);
  disableFields(creditSection);

  const toggleSections = (selectedType) => {
    if (selectedType === 'insurance') {
      insuranceSection.show();
      enableFields(insuranceSection);
      creditSection.hide();
      disableFields(creditSection);
    } else if (selectedType === 'credit') {
      creditSection.show();
      enableFields(creditSection);
      insuranceSection.hide();
      disableFields(insuranceSection);
    }
  };

  // Llamar a toggleSections al cambiar el tipo de financiamiento
  $('#financier_financing_type').on('change', function () {
    const selectedType = $(this).val();
    toggleSections(selectedType);
  });

  // Ejecutar la lógica al cargar la página para manejar la sección visible/invisible según el valor inicial
  const initialSelectedType = $('#financier_financing_type').val();
  toggleSections(initialSelectedType);

}

const setCountryToBilling = (countryID = null) => {
  const billingAddressCountry = $("#billing_address_country_id")
  const billingTarget = $("#financier_billing_target")
  if (billingTarget.val() === "insurer_invoice") {
    billingAddressCountry.val(countryID);
  } else {
    billingAddressCountry.val("");
  }
}

const startToggleCountries = () => {

  if ($('.admin-financier-form').length) {

    // Ejecutar al cargar la página
    toggleCountryFields();
    startToggleSections();

    // Escuchar el cambio en la selección del país
    $('#financier_country_select').on('change', function () {
      toggleCountryFields();
    });


    // Validación de los campos de dinero
    const moneyFields = [
      '#financier_min_value_cents',
      '#financier_max_value_cents',
      '#financier_fixed_monthly_charge_cents',
      '#financier_variable_monthly_charge_cents',
      '#financier_evaluation_limit_cents'
    ];

    moneyFields.forEach(function (selector) {
      $(selector).on('input', function () {
        let value = $(this).val();

        // Permitir solo números y puntos
        value = value.replace(/[^0-9.]/g, '');

        // Limitar a un solo punto decimal
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Limitar a 999.999.999
        const numericValue = parseFloat(value);
        if (numericValue > 999999999) {
          value = '999999999';
        }

        // Asegurar que sea positivo
        if (numericValue < 0) {
          value = Math.abs(numericValue).toString();
        }

        $(this).val(value);
      });
    });

    // Función para obtener el símbolo de la moneda
    const getCurrencySymbol = (currency) => {
      switch (currency) {
        case 'CLF':
          return 'UF';
        case 'CLP':

        case 'USD':

        default:
          return currency || '-';
      }
    };

    // Escuchar cambios en el selector de moneda
    $('.currency-selector').on('change', function () {
      const selectedCurrency = $(this).val();
      const currencySymbol = getCurrencySymbol(selectedCurrency);

      // Actualizar todos los elementos con el target 'currency-symbol'
      $('[data-currency-target="currency-symbol"]').text(currencySymbol);
    });

    // Ejecutar la lógica al cargar la página para establecer el símbolo correcto
    const initialCurrency = $('.currency-selector').val();
    const initialSymbol = getCurrencySymbol(initialCurrency);
    $('[data-currency-target="currency-symbol"]').text(initialSymbol);

  }
}

const startExclusiveProviders = () => {
  if ($('.admin-financier-form').length) {
    //codigo para campos de credit_exclusive y exclusive_providers
    $('.js-multiple-exclusive-providers').select2({
      width: '100%',
    });
    // Manejar visualización inicial del selector de prestadores exclusivos
    if ($('#credit_exclusive').is(':checked')) {
      $('.credit-exclusive-providers-select').show();
      $('.js-multiple-exclusive-providers').prop('disabled', false);
    } else {
      $('.credit-exclusive-providers-select').hide();
      $('.js-multiple-exclusive-providers').prop('disabled', true);
    }
    // Manejar cambio en el checkbox de credit_exclusive
    $('#credit_exclusive').change(function () {
      if ($(this).is(':checked')) {
        $('.credit-exclusive-providers-select').show();
        $('.js-multiple-exclusive-providers').prop('disabled', false);
      } else {
        $('.credit-exclusive-providers-select').hide();
        $('.js-multiple-exclusive-providers').prop('disabled', true);
      }
    });
  }
}

const activateButton = () => {
  const submitButton = $(".admin-financier-form").find('input[type="submit"]');

  if (submitButton) {
    submitButton.prop('disabled', false);
  }

}

const startValidateForm = () => {

  if ($(".admin-financier-form").length <= 0) {
    return
  }

  if (window.validator) {
    window.validator = validator
  } else {
    window.validator = new JustValidate('.admin-financier-form', globalConfigFormValidator);
  }

  $("#billingModal").on("show.bs.modal", () => {
    activateButton()
  })

  $("#billingModal").on("hidden.bs.modal", () => {
    $(".alert-form-errors").hide()
    window.validator.refresh()
    activateButton()
  })

  Object.entries(defaultFields).forEach(([key, values]) => {
    validator.addField(key, values);
  })

  window.validator.onFail(() => {
    $(".alert-form-errors").show()
    return $("#billingModal").modal('show');
  })

  window.validator.onSuccess(() => {
    $(".alert-form-errors").hide()
    if (document.querySelector(".rut-auto-format").classList.contains("is-invalid")) {
      $(".alert-form-errors").show()
      return $("#billingModal").modal('show');
    }

    $(".admin-financier-form").submit();

  })

}


const removeAllFields = () => {
  setCountryToBilling()
  // Eliminamos por si es que no hay fields del país, y si es que ya se agregaron
  for (const key in window.validator.fields) {
    const elem = window.validator.fields[key];
    if ({...allFields, ...defaultFields}.hasOwnProperty(`#${elem.elem.id}`)) {
      window.validator.removeField(`#${elem.elem.id}`);
    }
  }
}


const countryFields = {
  '142': mexicanFields,
  '50': colombianFields,
  '1': chileanFields
}

const addOrRemoveFieldsFormValidator = (countryID, create = false) => {
  if (!window.validator) {
    return
  }
  const billingTarget = $("#financier_billing_target")
  // Es facturación paciente? Eliminamos validaciones
  if (billingTarget.val() === "patient_invoice") {
    return removeAllFields()
  }

  // Eliminamos por si es que no hay fields del país, y si es que ya se agregaron
  for (const key in window.validator.fields) {
    const elem = window.validator.fields[key];
    if (allFields.hasOwnProperty(`#${elem.elem.id}`)) {
      window.validator.removeField(`#${elem.elem.id}`);
    }
  }

  if (create) {
    // Agregamos las otras si es que se crea de nuevo el form
    Object.entries(defaultFields).forEach(([key, values]) => {
      window.validator.addField(key, values)
    })
  }

  // Seleccionamos país y agregamos, luego eliminamos si es que
  if (countryFields[countryID.toString()]) {
    Object.entries(countryFields[countryID.toString()]).forEach(([key, values]) => {
      window.validator.addField(key, values)
    })
  }

  window.validator.refresh()
}

const startBillingTargetValidations = () => {
  const billingTarget = $("#financier_billing_target")
  billingTarget.on("select2:select", (e) => {
    addOrRemoveBillingValidations(e.target.value)
  })

  addOrRemoveBillingValidations(billingTarget.val())
}

const addOrRemoveBillingValidations = (billingTarget) => {
  if (window.validator) {
    const selectedCountryId = $('#financier_country_select').val();
    if (billingTarget === "insurer_invoice") {
      // Facturación requerida
      // Agregamos el billingAddress
      setCountryToBilling(selectedCountryId)
      addOrRemoveFieldsFormValidator(selectedCountryId, true)
      return
    } else {
      // AQUI NO VALIDAR RUT
      removeAllFields()
      // No requiere validación
      window.validator.destroy()
    }

  }
}

$(document).on('turbolinks:load', function () {

  if ($(".alert-form-errors").length > 0) {
    $(".alert-form-errors").hide()
  }
  startAutoSendToggles()
  startValidateForm()
  startToggleCountries()
  startExclusiveProviders()

  startBillingTargetValidations()

});